import { Table, TableCell, TableHead, TableRow } from "@material-ui/core";

import React from "react";
import { format } from "date-fns";
import { getLastMonthDate } from "utils/dateGetters";

const CV_ID = 15;
const AH_ID = 18;

const parseGetAllErrorsAndWarningsMerged = (observations) => {
  var allObservations = observations.map((elem) =>
    elem.error_id in mapObservations
      ? mapObservations[elem.error_id].label
      : elem.error_id,
  );
  return allObservations.join(" - ");
};

const defineCellColor = (parsedErrorsAndWarnings, info, distributor, alert) => {
  if (info.category === "deprecated") return;
  if (info.category !== "warnings") {
    parsedErrorsAndWarnings.paymentStatus = "Rechazado";
    if (
      (info.category !== "exlab" && info.category !== "discount") ||
      distributor !== CV_ID
    ) {
      parsedErrorsAndWarnings.cellColor = "red";
    } else if (
      (info.category === "exlab" || info.category === "discount") &&
      parsedErrorsAndWarnings.cellColor !== "red"
    ) {
      parsedErrorsAndWarnings.cellColor = "yellow";
    }
  }
};

const parseGetAllErrorsAndWarnings = (
  errors,
  warnings,
  distributor,
  isGES,
  isSalco,
  programId,
  calculatedDiscount,
  informedDiscount,
) => {
  const errorsAndWarnings = errors.concat(warnings);
  var parsedErrorsAndWarnings = {
    paymentStatus: "Aprobado",
    inscriptionDates: "",
    independents: "",
    units: "",
    purchaseNumber: "",
    discountPercent: "",
    inscriptionWarning: "",
    discount: "",
    ges: "",
    exlab: "",
    vademecum: "",
    patientInexistent: "",
    warnings: "",
    inscription: "",
    deprecated: "",
    presentations: "",
    cellColor: "",
  };
  errorsAndWarnings.forEach((alert) => {
    const programType = programId !== 1000 ? "tradicionales" : "quemables";
    const info = mapObservations[programType][alert.error_id];
    let category = info.category;
    // defineCellColor(parsedErrorsAndWarnings, info, distributor, alert);
    let labelToAdd = info.label;
    if (parsedErrorsAndWarnings[category] !== "")
      labelToAdd = ` - ${info.label}`;
    if (!parsedErrorsAndWarnings[category].includes(info.label)) {
      if ((isSalco || isGES) && labelToAdd === mapObservations[2]?.label)
        // This is for "INEXISTENT_PRESENTATION_ERROR" on Salco or GES programs
        category = isGES ? "ges" : "purchaseNumber"; // We changed the category for it to map to an included column (the default category is inscription and its excluded on Salco and GES)
      parsedErrorsAndWarnings[category] += labelToAdd;
    }
  });

  if (calculatedDiscount === 0) {
    parsedErrorsAndWarnings.cellColor = "red";
    parsedErrorsAndWarnings.paymentStatus = "Rechazado";
    return parsedErrorsAndWarnings;
  }
  // If we have warning 15 or 16 we should change the color to yellow
  if (
    errorsAndWarnings.some(
      (elem) => elem.error_id === 15 || elem.error_id === 16,
    ) ||
    (distributor === AH_ID && programId === 1000)
  ) {
    parsedErrorsAndWarnings.cellColor = "yellow";
    parsedErrorsAndWarnings.paymentStatus = "Aprobado con reparo";
    if (calculatedDiscount !== informedDiscount) {
      parsedErrorsAndWarnings.paymentStatus = "Aprobado con reparo - Corregido";
    }
    return parsedErrorsAndWarnings;
  }

  if (calculatedDiscount !== informedDiscount) {
    parsedErrorsAndWarnings.cellColor = "yellow";
    parsedErrorsAndWarnings.paymentStatus = "Corregido";
  } else if (calculatedDiscount === informedDiscount) {
    parsedErrorsAndWarnings.paymentStatus = "Aprobado";
    parsedErrorsAndWarnings.cellColor = "";
  }
  return parsedErrorsAndWarnings;
};

const getDateFormatted = (date) => {
  return format(date, "yyyy/MM");
};

const blankFilters = {
  period: getDateFormatted(
    new Date(
      getLastMonthDate().getFullYear(),
      getLastMonthDate().getMonth(),
      1,
    ),
  ),
  distributor: "",
  program: "",
  status: "",
  observations: [],
  importID: "",
  type: "total",
};

const summaryHeader = [
  {
    title: "Estado",
    align: "left",
  },
  {
    title: "Transacciones",
    align: "right",
  },
  {
    title: "Unidades",
    align: "right",
  },
  {
    title: "Descuento $",
    align: "right",
  },
  {
    title: "Fees",
    align: "right",
  },
  {
    title: "Fees %",
    align: "right",
  },
];
const detailedFeesColumns = [
  {
    title: "Descuento especial",
    align: "center",
  },
  {
    title: "Descuento logística y distribución",
    align: "center",
  },
  {
    title: "Descuento final",
    align: "center",
  },
  {
    title: "Fee",
    align: "center",
  },
];

// Oficial headers
const summaryRowHeader = [
  "Total liquidación",
  "Descuentos con errores",
  "Descuentos corregidos",
  "Diferencia",
  "A pagar",
];

const mapObservations = {
  tradicionales: {
    1: {
      category: "discountPercent",
      label: "Descuento % informado erróneo",
    }, // "INFORMED_DISCOUNT_PERCENTAGE_ZERO_ERROR"
    2: { category: "inscription", label: "Presentación inexistente" }, // "INEXISTENT_PRESENTATION_ERROR"
    3: {
      category: "units",
      label: "Unidades totales informadas excedidas",
    }, // "INFORMED_TOTAL_UNITS_EXCEEDED_ERROR"
    4: {
      category: "units",
      label: "Unidades totales informadas inválidas",
    }, // "INVALID_INFORMED_TOTAL_UNITS_ERROR"
    5: {
      category: "discount",
      label: "Descuento $ informado erróneo",
    }, // "INFORMED_DISCOUNT_ZERO_ERROR"
    6: {
      category: "inscription",
      label: "Empadronamiento inexistente para paciente",
    }, // "INEXISTENT_INSCRIPTION_FROM_PATIENT_WARNING" ---- USED TO BE "Paciente no posee el empadronamiento" changed to match id 19 (both errors now since july 2022)
    7: {
      category: "discountPercent",
      label: "Descuento % informado erróneo",
    }, // "INFORMED_DISCOUNT_PERCENTAGE_MISMATCHING_WARNING"
    8: { category: "exlab", label: "Precio EXLAB informado erróneo" }, // "INFORMED_EXLAB_PRICE_MISMATCHING_WARNING"
    9: {
      category: "ges",
      label: "Precio GES informado erróneo",
    }, // "INFORMED_GES_PRICE_MISMATCHING_WARNING"
    10: { category: "warnings", label: "RUT de médico inexistente" }, // "INEXISTENT_DOCTOR_RUT_WARNING"
    11: {
      category: "discount",
      label: "Descuento $ informado erróneo",
    }, // "INFORMED_DISCOUNT_MISMATCHING_WARNING"
    12: {
      category: "deprecated",
      label: "Costo total informado no coincidente",
    }, // "INFORMED_TOTAL_COST_MISMATCHING_WARNING"
    13: {
      category: "units",
      label: "Unidades informadas exceden beneficio",
    }, // "INFORMED_TOTAL_UNITS_EXCEEDED_WARNING"
    14: {
      category: "vademecum",
      label: "SKU no vigente en el programa",
    }, // INEXISTENT_VADEMECUM_FOR_PRESENTATION_ERROR
    15: {
      category: "ges",
      label: "Precio GES inexistente para presentación",
    }, // INEXISTENT_GES_PRICE_FOR_PRESENTATION_ERROR
    16: {
      category: "discountPercent",
      label: "Descuento Mi Salco inexistente",
    }, // INEXISTENT_MISALCOBRAND_DISCOUNT_FOR_PRESENTATION_ERROR
    17: {
      category: "purchaseNumber",
      label: "Número de compra no coincidente",
    }, // INFORMED_PURCHASE_NUMBER_MISMATCHING_WARNING
    18: { category: "patientInexistent", label: "Paciente inexistente" }, // INEXISTENT_PATIENT_ERROR
    19: {
      category: "inscription",
      label: "Empadronamiento inexistente para paciente",
    }, // INEXISTENT_INSCRIPTION_FROM_PATIENT_ERROR
    20: { category: "inscription", label: "Empadronamiento expirado" }, // EXPIRED_INSCRIPTION_ERROR
    21: { category: "warnings", label: "Empadronamiento expirado" }, // EXPIRED_INSCRIPTION_WARNING
    22: {
      category: "independents",
      label: "Distribuidor incorrecto para la fecha",
    }, // INCORRECT_DISTRIBUTOR_ERROR
    23: { category: "independents", label: "Farmacia inexistente." }, // INEXISTENT_PHARMACY_ERROR
    24: { category: "independents", label: "Fecha de venta invalida" }, // INVALID_SALE_DATE_ERROR
  },
  quemables: {
    1: { category: "inscription", label: "Cupón inexistente" }, // INEXISTENT_COUPON_ERROR
    2: { category: "inscription", label: "Cupón no asignado a paciente" }, // INEXISTENT_INSCRIPTION_ERROR
    3: { category: "inscription", label: "Cupón ya utilizado" }, // COUPON_ALREADY_USED_ERROR
    4: { category: "independents", label: "Farmacia inexistente" }, // INEXISTENT_PHARMACY_ERROR
    5: { category: "independents", label: "Distribuidor inexistente" }, // INEXISTENT_DISTRIBUTOR_ERROR
    6: { category: "vademecum", label: "Presentación inexistente" }, // INEXISTENT_PRESENTATION_ERROR
    7: { category: "vademecum", label: "SKU no vigente en el programa" }, // INEXISTENT_VADEMECUM_ERROR
    8: { category: "warnings", label: "Presentación incorrecta" }, // INCORRECT_PRESENTATION_WARNING
    9: { category: "units", label: "Unidades excedidas" }, // TOO_MANY_UNITS_WARNING
    10: { category: "discountPercent", label: "Descuento % incorrecto" }, // INCORRECT_DISCOUNT_PERCENTAGE
    11: { category: "discount", label: "Descuento incorrecto" }, // INCORRECT_DISCOUNT
    12: { category: "exlab", label: "Precio EXLAB incorrecto" }, // INCORRECT_EXLAB_PRICE
    13: { category: "independents", label: "Ventas mensuales excedidas" }, // MAXIMUM_SALES_EXCEEDED_ERROR
    14: { category: "inscription", label: "Paciente inexistente " }, // INEXISTENT_PATIENT_ERROR
    15: { category: "warnings", label: "Cupón ya utilizado" }, // COUPON_ALREADY_USED_WARNING
    16: { category: "warnings", label: "Presentación autocompletada" }, // INEXISTENT_PRESENTATION_WARNING
    17: {
      category: "inscription",
      label: "Paciente superó límite de productos",
    }, // MAX_SIMULTANEOUS_INSCRIPTIONS_ERROR
    18: { category: "inscription", label: "Paciente excedió beneficio" }, // ALREADY_FINISHED_INSCRIPTION_ERROR = 18
  },
};

// Color variables
let primaryOpacity = "0.7";
let secondaryOpacity = "0.5";
let tempColor = "255, 255, 255"; // white

// Create the columns for the main table
function CreateColumns(isGES, isSalco, isBurnable = false) {
  return [
    {
      name: "Nro. venta",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Fecha",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Período",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            position: "sticky",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Sucursal",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Cód. presentación",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "SKU",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Presentación informada",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          align: "center",
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Presentación suscrita",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          align: "center",
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Tipo de cupón",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          align: "center",
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Contactable",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          align: "center",
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Código de barras",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          align: "center",
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: isSalco ? "Unidad de avance infor" : "infor Cant.",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>
                    {isSalco ? "Unidad de avance" : "Cant."}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "infor ExLab",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",

            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ExLab</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "infor Total",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>Informado</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },

    {
      name: isGES ? "infor GES Total" : "infor Desc. %", // If program is GES
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>{isGES ? "GES Total" : "Desc. %"}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "infor Desc. $",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Desc. $</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: isSalco ? "Unidad de avance calc" : "calc Cant.",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table stickyHeader={true}>
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>
                    {isSalco ? "Unidad de avance" : "Cant."}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "calc ExLab",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>ExLab</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: "calc Total",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4} style={{ padding: "4px" }}>
                    <strong>Calculado</strong>
                  </TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },

    {
      name: isGES ? "calc GES Total" : "calc Desc. %", // If program is GES
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell>{isGES ? "GES Total" : "Desc. %"}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: isSalco ? "Bonificación calculada" : " calc Desc. $",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
        customHeadLabelRender: (props) => {
          let headerStyle = {
            whiteSpace: "nowrap",
            position: "sticky",
            top: 0,
            zIndex: 2,
            stickyHeader: true,
          };
          return (
            <Table
              style={{ display: "flex", alignItems: "center" }}
              stickyHeader={true}
            >
              <TableHead stickyHeader={true}>
                <TableRow style={{ ...headerStyle }}>
                  <TableCell colspan={4}></TableCell>
                </TableRow>
                <TableRow
                  style={{ ...headerStyle, zIndex: 1 }}
                  stickyHeader={true}
                >
                  <TableCell> {isSalco ? "Bonificación" : "Desc. $"}</TableCell>
                </TableRow>
              </TableHead>
            </Table>
          );
        },
      },
    },
    {
      name: isSalco ? "Diferencia bonificación" : "Diferencia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Ticket",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Zona",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            fontWeight: "bold",
            textAlign: "center",
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Estado pago",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Fecha de empadronamiento",
      options: {
        display: isSalco || isGES ? "exclude" : true,
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
        sortCompare: (order) => {
          return (a, b) => {
            if (!a.data) return -1 * (order === "desc" ? 1 : -1);
            if (!b.data) return 1 * (order === "desc" ? 1 : -1);
            var dateSplitA = a.data.split("/");
            var dateSplitB = b.data.split("/");
            const dateA = new Date(
              dateSplitA[2],
              dateSplitA[1],
              dateSplitA[0],
            ).getTime();
            const dateB = new Date(
              dateSplitB[2],
              dateSplitB[1],
              dateSplitB[0],
            ).getTime();
            return (dateA < dateB ? -1 : 1) * (order === "desc" ? 1 : -1);
          };
        },
      },
    },
    {
      name: "Comentario empadronamiento",
      options: {
        display: isSalco || isGES ? "exclude" : true,
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario unidades",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario unidad de avance",
      options: {
        filter: false,
        display: isSalco ? true : "excluded",
        setCellHeaderProps: () => ({
          rowSpan: 2,
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario descuento ( % )",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario descuento ( $ )",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario EXLAB",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",

            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario Vademécum",
      options: {
        display: isSalco || isGES ? "exclude" : true,
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario GES",
      options: {
        display: isGES ? true : "excluded",
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Comentario paciente inexistente",
      options: {
        display: isSalco ? true : "excluded",
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",

            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Errores de independientes",
      options: {
        display: isSalco || isGES ? "excluded" : true,
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Advertencias",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "ISAPRE",
      options: {
        filter: false,
        display: isGES ? true : "excluded",
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: isSalco ? "Descuento financiado por Salco" : "Descuento total",
      options: {
        filter: false,
        display: isSalco ? true : "excluded",
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: isSalco ? "Descuento financiado por Abbott" : "Descuento Abbott",
      options: {
        filter: false,
        display: isSalco ? true : "excluded",
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${secondaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Grupo",
      options: {
        filter: true,
        filterType: "dropdown",
        display: "excluded",
        filterOptions: {
          fullWidth: true,
        },
      },
    },

    {
      name: "ID Paciente",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Distribuidor",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Nombre de farmacia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "RUT de farmacia",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Código CFR",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
    {
      name: "Color de celda",
      options: {
        display: "excluded",
        filter: false,
        setCellHeaderProps: () => ({
          rowSpan: 2,
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
          },
        }),
        setCellProps: (cellValue, rowIndex, columnIndex) => ({
          style: {
            whiteSpace: "nowrap",
            textAlign: "center",
            backgroundColor: `rgba(${tempColor},${primaryOpacity})`,
          },
        }),
      },
    },
  ];
}

export {
  summaryHeader,
  summaryRowHeader,
  detailedFeesColumns,
  mapObservations,
  blankFilters,
  parseGetAllErrorsAndWarnings,
  parseGetAllErrorsAndWarningsMerged,
  getDateFormatted,
  CreateColumns as createColumns,
};
