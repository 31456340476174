import React from "react";
// Components
import { Typography } from "components/Wrappers/Wrappers";
import dataGridTexts from "components/DataGrid/dataGridTexts.js";
import { DataGrid } from "@material-ui/data-grid";
import PopupMessage from "components/PopupMessage/PopupMessage";
// Functions and helpers
import { createSubscriptionDetailsColumns } from "pages/burnable/ConsultUserDataPage/table-helpers";

export default function SubscriptionDetailsDialog({
  row,
  open,
  onClose,
  patient,
  openResendConfirmation,
}) {
  const subscriptionDetailsColumns = createSubscriptionDetailsColumns(
    openResendConfirmation,
  );

  return (
    <PopupMessage
      open={open}
      handleClose={onClose}
      title={`Detalles de la suscripción ${row?.id}`}
      closeColor={"secondary"}
      maxWidth={false}
      contentStyle={{
        width: "700px",
        maxWidth: "90vw",
        height: "700px",
        maxHeight: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        Producto: {row?.presentation}
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 10, textWrap: "wrap" }}>
        Nombre del paciente: {patient?.name} {patient?.lastname}
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        RUT del paciente: {patient?.rut}
      </Typography>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: "100px",
          maxHeight: "calc(90vh - 150px)",
        }}
      >
        <DataGrid
          rows={row?.automatic_emails ?? []}
          columns={subscriptionDetailsColumns}
          disableColumnMenu={true}
          pageSize={100}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          disableColumnSelector={true}
          disableDensitySelector={true}
          showToolbar={true}
          localeText={dataGridTexts}
          hideFooter={true}
          getRowClassName={(params) => `super-app-theme--${params.row.type}`}
          style={{
            height: "100%",
            overflow: "auto",
          }}
        />
      </div>
    </PopupMessage>
  );
}
