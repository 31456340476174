import React, { useState, useEffect } from "react";
import Field from "components/CustomFields/Field";
import { Button } from "@material-ui/core";

/**
 * @component
 * 
 * @param {object} props
 * @param {object} props.patientData - Datos del paciente que se mostrarán en los campos del formulario.
 * @param {string} props.patientData.name - Nombre completo del paciente.
 * @param {string} props.patientData.email - Correo electrónico del paciente.
 * @param {string} props.patientData.phone - Número de teléfono del paciente.
 * @param {object} props.classes - Clases de estilo aplicadas a los elementos del componente.
 * @param {string} props.classes.label - Clase CSS aplicada a las etiquetas del formulario.
 * @param {string} props.classes.generalPurposeItems2 - Clase CSS aplicada al contenedor de los campos.
 * @param {string} props.classes.generalPurposeField - Clase CSS aplicada a los campos de entrada.
 * 
 * @returns {React.Element} El formulario de información editable del paciente.
 */
const EditablePatientInfo = ({ patientData, classes, onSave }) => {
  const [editableData, setEditableData] = useState(patientData);

  useEffect(() => {
    setEditableData(patientData);
  }, [patientData]);
  
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    const field = id.split('-')[1]; // "Hack" to avoid autocomplete

    const updatedData = {
      ...editableData,
      [field]: value,
    };

    setEditableData(updatedData);
  };

  const handleSaveChanges = () => {
    onSave(editableData);
  };

  return (
    <>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: "1rem",
          rowGap: "0.75rem",
        }}
      >
        <label htmlFor="patientName" className={classes.label}>
          Nombre
        </label>
        <Field
          required={false}
          id="patient-name" // Google ignores certain autocomplete: "off", so I change the id.
          disabled={false}
          value={editableData.name}
          onChange={handleInputChange}
          divClassType={classes.generalPurposeItems3}
          fieldClassType={classes.generalPurposeField}
          InputProps={{ autoComplete: "off" }}
        />
        <label htmlFor="patientName" className={classes.label}>
          Apellido
        </label>
        <Field
          required={false}
          id="patient-lastname" // Google ignores certain autocomplete: "off", so I change the id.
          disabled={false}
          value={editableData.lastname}
          onChange={handleInputChange}
          divClassType={classes.generalPurposeItems3}
          fieldClassType={classes.generalPurposeField}
          InputProps={{ autoComplete: "off" }}
        />
        <label htmlFor="patientEmail" className={classes.label}>
          Email
        </label>
        <Field
          required={false}
          id="patient-email"
          disabled={false}
          value={editableData.email}
          onChange={handleInputChange}
          divClassType={classes.generalPurposeItems3}
          fieldClassType={classes.generalPurposeField}
          InputProps={{ autoComplete: "off" }}
        />
        <label htmlFor="patientPhone" className={classes.label}>
          Teléfono
        </label>
        <Field
          required={false}
          id="patient-phone"
          disabled={false}
          value={editableData.phone}
          onChange={handleInputChange}
          divClassType={classes.generalPurposeItems3}
          fieldClassType={classes.generalPurposeField}
          InputProps={{ autoComplete: "off" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5rem"
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveChanges}
          style={{
            justifySelf: "center",
            width: "fit-content",
            marginRight: "4.75rem"
          }}
        >
          Guardar cambios
        </Button>
      </div>
    </>
  );
};

export default EditablePatientInfo;
