const programs = {
  1: "Receta solidaria",
  2: "Solo mujer",
  3: "GES",
  4: "Mi Salcobrand",
};

const inscriptionsColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 100,
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "name",
    headerName: "Producto",
    type: "string",
    minWidth: 400,
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "type",
    headerName: "Origen",
    minWidth: 120,
    type: "string", // to sort correctly
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "transaction_date",
    headerName: "Fecha",
    minWidth: 120,
    type: "string", // to sort correctly
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "distributor",
    headerName: "Cadena",
    minWidth: 200,
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "pharmacy",
    headerName: "Farmacia",
    minWidth: 120,
    align: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "remaining_units",
    headerName: "Compras restantes",
    minWidth: 180,
    type: "number",
    align: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "errors",
    headerName: "Errores",
    minWidth: 400,
    type: "string",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "program",
    headerName: "Programa",
    minWidth: 200,
    type: "string",
    align: "left",
    valueGetter: (params) => programs[params.row?.program],
    headerClassName: "super-app-theme--Header",
  },
];

const salesColumns = [
  {
    field: "id",
    headerName: "ID",
    minWidth: 100,
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "name",
    headerName: "Producto",
    minWidth: 400,
    type: "string",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "type",
    headerName: "Origen",
    minWidth: 120,
    type: "string", // to sort correctly
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "transaction_date",
    headerName: "Fecha",
    minWidth: 120,
    type: "string", // to sort correctly
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "distributor",
    headerName: "Cadena",
    minWidth: 200,
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "pharmacy",
    headerName: "Farmacia",
    minWidth: 120,
    align: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "total_units",
    headerName: "Unidades",
    minWidth: 120,
    type: "number",
    align: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "discount_percentage",
    headerName: "Descuento (%)",
    minWidth: 155,
    type: "number",
    align: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "errors",
    headerName: "Errores",
    minWidth: 400,
    type: "string",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "program",
    headerName: "Programa",
    minWidth: 200,
    type: "string",
    align: "left",
    valueGetter: (params) => programs[params.row?.program],
    headerClassName: "super-app-theme--Header",
  },
];

const onlineCouponsColumns = [
  {
    field: "email",
    headerName: "Email",
    minWidth: 300,
    type: "string",
    align: "left",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "sent_date",
    headerName: "Envío",
    minWidth: 200,
    type: "string",
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "delivered_date",
    headerName: "Recepción",
    minWidth: 200,
    type: "string",
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--Header",
  },
  {
    field: "opened_date",
    headerName: "Apertura",
    minWidth: 200,
    type: "string",
    align: "center",
    headerAlign: "center",
    headerClassName: "super-app-theme--Header",
  },
]

export { salesColumns, inscriptionsColumns, onlineCouponsColumns };