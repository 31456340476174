import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
} from "@material-ui/core";

export default function PopupMessage({
  handleClose,
  open,
  title,
  message,
  buttonClassName,
  buttonDivClassName,
  executeButton,
  children,
  closeColor,
  maxWidth,
  contentStyle
}) {
  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={maxWidth}
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="h4">{title}</Typography>
          </DialogTitle>
          <DialogContent style={contentStyle}>
            <DialogContentText id="alert-dialog-description">
              <Typography variant="body1">{message}</Typography>
            </DialogContentText>
            {children}
          </DialogContent>
          <DialogActions className={buttonDivClassName}>
            {executeButton}
            <Button
              onClick={handleClose}
              variant="contained"
              color={closeColor || "primary"}
              className={buttonClassName}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
