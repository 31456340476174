import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { parseISO } from "date-fns";

const ResendEmailButton = ({ onClick, label }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    }}
  >
    <Button
      onClick={onClick}
      size="small"
      style={{
        padding: 8,
      }}
      color="primary"
    >
      {label}
    </Button>
  </div>
);

const createColumn = (
  field,
  headerName,
  minWidth = 160,
  type = "string",
  align = "left",
  additionalProps = {},
) => ({
  field,
  headerName,
  minWidth,
  type, // to sort correctly
  align,
  headerAlign: align,
  sortable: false,
  headerClassName: "super-app-theme--Header",
  renderCell: (params) => {
    const isDeleted = params.row.automatic_emails?.some(
      (email) => email.is_deleted,
    );
    return (
      <div
        style={{
          textDecoration: isDeleted ? "line-through" : "none",
          color: isDeleted ? "gray" : "inherit",
        }}
      >
        {params.value}
      </div>
    );
  },
  ...additionalProps,
});

const createTextActionColumn = (headerName, field, onClick, width = 50) => ({
  field: field,
  sortable: false,
  headerName,
  headerAlign: "center",
  width,
  renderCell: (params) =>
    params.value ? (
      <ResendEmailButton
        onClick={() => onClick(params)}
        key={field}
        label="Reenviar"
      />
    ) : (
      <div />
    ),
});

const createEmailDetailsColumn = (setIsDetailsOpen, setSelectedRow) => {
  return {
    field: "details",
    sortable: false,
    headerName: "Ver Emails",
    headerAlign: "center",
    width: 110,
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <IconButton
          key={"details"}
          onClick={() => {
            const { row } = params;
            setIsDetailsOpen(true);
            setSelectedRow(row);
          }}
          size="small"
          style={{
            padding: 8,
          }}
        >
          <span style={{ fontSize: "1.25rem" }}>↗︎</span>
        </IconButton>
      </div>
    ),
  };
};

const createUnsubscribeColumn = (setIsUnsubscribeOpen, setSelectedRow) => {
  return {
    field: "unsubscribe",
    sortable: false,
    headerName: "Desuscribir",
    headerAlign: "center",
    width: 110,
    renderCell: (params) => {
      const { row } = params;

      if (row.automatic_emails?.some((email) => email.is_deleted)) {
        return null; // No rendering
      }

      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            key={"details"}
            onClick={() => {
              setIsUnsubscribeOpen(true);
              setSelectedRow(row);
            }}
            size="small"
            style={{
              padding: 8,
            }}
          >
            <CloseIcon style={{ fontSize: "1.25rem" }} />
          </IconButton>
        </div>
      );
    },
  };
};

const createSubscriptionsColumns = (
  setSelectedRow,
  setIsDetailsOpen,
  setIsUnsubscribeOpen,
) => [
  createColumn("id", "ID", 100, "number", "center", {
    valueFormatter: (params) => {
      return `${Number(params.value)}`;
    },
  }),
  createColumn("presentation", "Presentación", 340),
  createColumn("type", "Origen", 250, "string", "center"),
  createColumn(
    "subscription_date",
    "Fecha Suscripción",
    180,
    "string",
    "center",
  ),
  createEmailDetailsColumn(setIsDetailsOpen, setSelectedRow),
  createUnsubscribeColumn(setIsUnsubscribeOpen, setSelectedRow),
];

const salesWithoutCouponColumns = [
  createColumn("presentation", "Presentación", 450),
  createColumn(
    "subscription_date",
    "Fecha Suscripción",
    200,
    "string",
    "center",
  ),
];

const getFormattedDate = (date) => {
  // Creo la fecha sin ajustar la zona horaria
  const dateParts = date.split("-");
  const year = dateParts[0];
  const month = dateParts[1];
  const day = dateParts[2];

  return `${day}/${month}/${year}`;
};

const parseAndFormatDate = (date) => {
  const parsedDate = parseISO(date);
  return parsedDate.toLocaleDateString("es-CL");
};

const mapSubscriptions = (subscriptions) => {
  if (!subscriptions) return [];

  return subscriptions.map((sub) => ({
    id: sub.id,
    presentation: sub.presentation.name,
    type: sub.origin,
    subscription_date: getFormattedDate(sub.transaction_date),
    automatic_emails: sub.automatic_emails.map((automatic_email) => ({
      id: automatic_email.id,
      email: automatic_email.email,
      date_to_send: automatic_email.date_to_send
        ? parseAndFormatDate(automatic_email.date_to_send)
        : "-",
      was_sent: automatic_email.was_sent,
      is_deleted: automatic_email.is_deleted,
      sent_date: automatic_email.events?.Send
        ? parseAndFormatDate(automatic_email.events?.Send)
        : "-",
      delivered_date: automatic_email.events?.Delivery
        ? parseAndFormatDate(automatic_email.events?.Delivery)
        : "-",
      opened_date: automatic_email.events?.Open
        ? parseAndFormatDate(automatic_email.events?.Open)
        : "-",
    })),
  }));
};

const mapSalesWithoutCoupon = (sales) => {
  if (!sales) return [];

  return sales.map((sale) => ({
    id: sale.id,
    presentation: sale.presentation.name,
    subscription_date: getFormattedDate(sale.transaction_date),
  }));
};

const createSubscriptionDetailsColumns = (openResendConfirmation) => [
  createColumn("email", "Email", 300),
  // createColumn("date_to_send", "Fecha Envío", 140, "string", "center"),
  {
    field: "date_to_send",
    headerName: "Programado para",
    width: 170,
    align: "center",
    renderCell: (params) => (
      <span
        style={{
          // If email is deleted, show date_to_send with a strike-through
          textDecoration: params.row.is_deleted ? "line-through" : "none",
        }}
      >
        {params.value}
      </span>
    ),
  },
  createColumn("sent_date", "Envío", 140, "string", "center", {
    sortable: true,
  }),
  createColumn("delivered_date", "Recepción", 140, "string", "center", {
    sortable: true,
  }),
  createColumn("opened_date", "Apertura", 140, "string", "center", {
    sortable: true,
  }),
  createTextActionColumn(
    "Reenviar",
    "was_sent",
    ({ id }) => openResendConfirmation(id),
    100,
  ),
];

export {
  createSubscriptionsColumns,
  salesWithoutCouponColumns,
  createSubscriptionDetailsColumns,
  mapSubscriptions,
  mapSalesWithoutCoupon,
};
